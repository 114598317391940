<template>
    <div id="signin-callback">
        Login successful, jumping
    </div>
</template>
<script>
import Cache from '@/utils/cache';
import Oidc from 'oidc-client';
export default {
    mounted() {
        this.init();
    },
    methods: {
        init() {
            new Oidc.UserManager()
                .signinRedirectCallback()
                .then(function() {
                    console.info('登录成功');

                    let str = '?';
                    let query = Cache.getSession('query');
                    for (var item in query) {
                        str += item + '=' + query[item] + '&';
                    }

                    window.location = '/home' + str;
                    //    window.location = "/postList";
                    // window.location = "http://post.aigostar.com:8081/#/postList";
                })
                .catch(function(e) {
                    window.location = '/';
                    // console.error(e);
                });
        }
    }
};
</script>
